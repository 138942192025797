import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import NavBar from './Components/NavBar';
import Footer from './Components/footer';

const Home = lazy(() => import('./Pages/Home'));
const Sueños = lazy(() => import('./Pages/Sueños'));
const Arroyito = lazy(() => import('./Pages/Arroyito'));
const Homm = lazy(() => import('./Pages/Homm'));
const Costa = lazy(() => import('./Pages/Costa'));
const Brothers = lazy(() => import('./Pages/Brothers'));
const Cora = lazy(() => import('./Pages/Cora'));
const MTSS = lazy(() => import('./Pages/MTSS'));
const DA = lazy(() => import('./Pages/DA'));
const Sierra = lazy(() => import('./Pages/Sierra'));
const Full = lazy(() => import('./Pages/Full'));
const Ucsmart = lazy(() => import('./Pages/Ucsmart'));
const Tres = lazy(() => import('./Pages/Tres'));
const Sinap = lazy(() => import('./Pages/Sinap'));


const App = () => {
	return (
		<>
			<NavBar />
			<Suspense fallback={<div className="container">Loading...</div>}>			
				<Routes>
					<Route path="/" element={<Home />}  />			
				
					<Route path="/sueños" element={<Sueños />} />
					<Route path="/arroyito" element={<Arroyito />} />
					<Route path="/Costa" element={<Costa />} />
					<Route path="/homm" element={<Homm />} />
					<Route path="/brothers" element={<Brothers />} />
					<Route path="/cora" element={<Cora />} />
					<Route path="/da" element={<DA />} />
					<Route path="/mtss" element={<MTSS />} />
					<Route path="/sierra" element={<Sierra />} />
					<Route path="/full" element={<Full />} />
					<Route path="/ucsmart" element={<Ucsmart />} />
					<Route path="/tres" element={<Tres />} />
					<Route path="/sinap" element={<Sinap />} />
				</Routes>
				
			</Suspense>
			<Footer/>
			
			
		</>
	);
};

export default App;
